<template>
  <div class="md-layout md-gutter" style="margin: 40px">
    <div v-for="(image, index) in processedImages" :key="index"
         class="md-layout-item md-large-size-25 md-medium-size-50 md-small-size-100"
         style="margin-bottom: 20px">
      <md-card>
        <md-card-area md-inset>
          <md-card-media>
            <VueCompareImage :leftImage="image.original" :rightImage="image.processed"
                             style="image-rendering: pixelated;"/>
          </md-card-media>
        </md-card-area>

        <md-card-content>
          <div class="md-subheading"><h2>Stats</h2></div>
          <div class="md-layout">
            <div class="md-layout-item">
              <h3>{{ image.originalTitle }}</h3>
              <span>Colors: {{ image.originalColorsCount }}</span>
            </div>
            <div class="md-layout-item">
              <h3>{{ image.processedTitle }}</h3>
              <span>Colors: {{ image.processedColorsCount }}</span>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import VueCompareImage from 'vue-compare-image';

export default {
  name: 'ProcessedImages',
  components: {VueCompareImage},
  props: {
    processedImages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    revokeURL(url) {
      URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>
.processed-image-card img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast; /* For WebKit browsers (e.g., Safari, Chrome) */
  image-rendering: -moz-crisp-edges; /* For Firefox */
  image-rendering: crisp-edges; /* For the standard */
  image-rendering: pixelated; /* Alternative for the standard */
}
</style>
