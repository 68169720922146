import {readMetadataFromPng} from "@/utils/image-info";

export class ProcessingQueue {
    constructor() {
        this.queue = [];
        this.processing = false;
        this.abortController = null;
        this.worker = new Worker('workers/image-processing.worker.js');

        window.addEventListener("beforeunload", () => {
            this.abortAllProcessing();
        });

        this.worker.addEventListener("message", (event) => this.handleProcessingResult(event.data.result, event.data.error));
    }

    add(imageData) {
        this.queue.push(imageData);
        this.processNext();
    }
    
    remove(imageData) {
        const index = this.queue.findIndex((item) => item === imageData);

        if (index !== -1) {
            if (index === 0 && this.processing) {
                // If the image is currently being processed, abort the worker.
                this.abortCurrentProcessing();
            }

            this.queue.splice(index, 1);
        }
    }

    handleProcessingResult(result, error) {
        if (error) {
            console.error(error);
        }

        const imageData = this.queue.shift();
        if (imageData) {
            if (result) {
                let processedImageSrc = this.createImageFromPixelArray(result.pixels, result.width, result.height);
                imageData.processingFinished(processedImageSrc);
            } else if (error) {
                imageData.processingFailed(error);
            }

            this.processing = false;
            this.processNext();
        }
    }

    createImageFromPixelArray(pixelArray, width, height) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;

        const imageData = ctx.createImageData(width, height);
        imageData.data.set(pixelArray);
        ctx.putImageData(imageData, 0, 0);

        return canvas.toDataURL('image/png');
    }

    async processNext() {
        if (this.processing) return;
        if (this.queue.length === 0) {
            this.processing = false;
            return;
        }

        this.processing = true;
        const inputData = this.queue[0];
        this.abortController = new AbortController();

        // Convert the HTMLImageElement to ImageData
        const canvas = document.createElement("canvas");
        canvas.width = inputData.sourceImg.width;
        canvas.height = inputData.sourceImg.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(inputData.sourceImg, 0, 0);

        inputData.metadata = await readMetadataFromPng(inputData.file);
        console.log('Metadata: ', inputData.metadata);

        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        
        console.log("imgData: ", imgData);
        console.log("inputData: ", inputData);

        const preprocessedData = await this.preprocessImage(canvas, imgData, inputData.processingStrategy);
        
        console.log('Preprocessed data: ', preprocessedData);

        this.worker.postMessage({
            processingStrategyName: inputData.processingStrategy,
            pixels: preprocessedData.imageData.data,
            width: preprocessedData.width,
            height: preprocessedData.height,
            settings: inputData.settings,
        });
    }

    abortAllProcessing() {
        if (this.abortController) {
            this.abortController.abort();
        }
        this.queue = [];
    }

    abortCurrentProcessing() {
        if (this.abortController) {
            this.abortController.abort();
        }
    }

    async preprocessImage(inputCanvas, inputImageData, processingStrategyName) {
        switch (processingStrategyName) {
            case 'PixelPaintingStrategy': {
                const resizer = new Resizer();
                return await resizer.resizePixelPainting(inputCanvas, inputImageData);
            }
            case 'DefaultStrategy':
                return {
                    imageData: inputImageData,
                    width: inputImageData.width,
                    height: inputImageData.height,
                };
        }
    }
}

class Resizer {
    constructor() {
        this.sizeMap = {
            512: {targetWidth: 64, targetHeight: 64},
            1024: {targetWidth: 128, targetHeight: 128}
        };
    }

    createCanvasAndContext(width, height) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;

        return {canvas, ctx};
    }

    getTargetSize(width, height) {
        if (width in this.sizeMap && height in this.sizeMap) {
            return this.sizeMap[width];
        }
        return null;
    }

    async resizePixelPainting(inputCanvas, imgData) {
        const {width, height} = imgData;

        if (width !== height) {
            throw new Error('Input image must have equal width and height');
        }

        const targetSize = this.getTargetSize(width, height);
        if (!targetSize) {
            return {
                imageData: imgData,
                width: width,
                height: height
            };
        }

        const {targetWidth, targetHeight} = targetSize;
        let {ctx} = this.createCanvasAndContext(targetWidth, targetHeight);

        ctx.drawImage(inputCanvas, 0, 0, width, height, 0, 0, targetWidth, targetHeight);
        const resizedImageData = ctx.getImageData(0, 0, targetWidth, targetHeight);
        console.log('Resized image data: ', resizedImageData);

        ctx = null;

        return {
            imageData: resizedImageData,
            width: targetWidth,
            height: targetHeight
        };
    }
}