export function reduceColors(colorGroups, similarityThreshold, colorComparisionMethod) {
    return measureMethodDuration(reduceColorsWorker, 'reduceColors', colorGroups, similarityThreshold, colorComparisionMethod);
}

function reduceColorsWorker(colorGroups, similarityThreshold, colorComparisionMethod) {
    console.log("similarity threshold: " + similarityThreshold);

    function findMostSimilarGroup(currentGroup, groups) {
        let maxSimilarity = 0;
        let mostSimilarGroup = null;

        for (const group of groups) {
            if (currentGroup === group) continue; // Skip the current group

            const groupColor = group.color;
            const similarity = calculateColorSimilarity(currentGroup.color, groupColor, colorComparisionMethod);

            if (similarity > maxSimilarity) {
                maxSimilarity = similarity;
                mostSimilarGroup = group;
            }
        }

        return {mostSimilarGroup, maxSimilarity};
    }

    function calculateColorSimilarity(color1, color2, colorComparisionMethod) {
        if (colorComparisionMethod.toLowerCase() === "euclidean") {
            return colorSimilarity(color1, color2);
        } else if (colorComparisionMethod.toLowerCase() === "ciede2000") {
            return colorSimilarityCIEDE2000(color1, color2);
        }
    }

    const newColorGroups = [...colorGroups];
    
    try {

        newColorGroups.sort((a, b) => b.pixels.length - a.pixels.length);
        for (let i = newColorGroups.length - 1; i >= 0; i--) {
            const group = newColorGroups[i];
            const {mostSimilarGroup, maxSimilarity} = findMostSimilarGroup(group, newColorGroups);

            if (maxSimilarity >= similarityThreshold && group !== mostSimilarGroup) {

                if (group.pixels.length < mostSimilarGroup.pixels.length) {
                    mostSimilarGroup.pixels.push(...group.pixels);
                    newColorGroups.splice(newColorGroups.indexOf(group), 1);
                    // i--; // После удаления группы, индекс текущей группы уменьшается на 1
                } else {
                    group.pixels.push(...mostSimilarGroup.pixels);
                    let mostSimilarGroupIndex = newColorGroups.indexOf(mostSimilarGroup);
                    newColorGroups.splice(mostSimilarGroupIndex, 1);
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    return newColorGroups;
}

function measureMethodDuration(method, methodName, ...args) {
    const startTime = performance.now();
    const result = method(...args);
    const endTime = performance.now();
    const duration = endTime - startTime;

    console.log(`${methodName} duration: ${duration} ms`);
    return result;
}

const MAX_DISTANCE = Math.sqrt(255 ** 2 * 3);

function colorSimilarity(color1, color2) {
    const [r1, g1, b1] = color1;
    const [r2, g2, b2] = color2;
    const squaredDistance = Math.sqrt((r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2);
    return 1 - squaredDistance / MAX_DISTANCE;
}

const d3 = require('d3-color');
const DeltaE = require('delta-e');

function rgbToLab(color) {
    const {r, g, b} = d3.rgb(...color);
    const {l, a, b: labB} = d3.lab(r, g, b);
    return {L: l, A: a, B: labB};
}

function colorDifferenceCIEDE2000(color1, color2) {
    const lab1 = rgbToLab(color1);
    const lab2 = rgbToLab(color2);
    return DeltaE.getDeltaE00(lab1, lab2);
}

function colorSimilarityCIEDE2000(color1, color2) {
    const MAX_DELTA_E = 100;
    const deltaE = colorDifferenceCIEDE2000(color1, color2);
    const normalizedDeltaE = deltaE / MAX_DELTA_E;
    const similarity = 1 - normalizedDeltaE;

    // Clamping the similarity value to the [0, 1] range
    return Math.min(Math.max(similarity, 0), 1);
}
