// noinspection SpellCheckingInspection
import * as UPNG from 'upng-js';

export async function readMetadataFromPng(imgSrc) {
    try {
        const arrayBuffer = await getByteArrayFromFile(imgSrc);
        const image = UPNG.decode(arrayBuffer);
        const tabs = image.tabs || {};
        const textTab = tabs['tEXt'];
        if (textTab) {
            return textTab.parameters;
        } else {
            return '';
        }
    } catch (error) {
        console.error(`Error reading metadata from PNG: ${error}`);
        return '';
    }
}


async function getByteArrayFromFile(file) {
    return new Promise((resolve, reject) => {
        // Create a new FileReader object
        const reader = new FileReader();

        // Set up an event listener for when the file is loaded
        reader.onload = (event) => {
            // Get the byte array from the loaded file
            const byteArray = event.target.result;

            // Resolve the promise with the byte array
            resolve(byteArray);
        };

        // Set up an event listener for when an error occurs
        reader.onerror = (event) => {
            // Reject the promise with the error message
            reject(event.target.error.message);
        };

        // Read the file as an array buffer
        reader.readAsArrayBuffer(file);
    });
}
