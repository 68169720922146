import Vue from 'vue';
import Router from 'vue-router';

// Import your components
import QuantizationPlayground from './components/QuantizationPlayground/QuantizationPlayground.vue';
import ImagesProcessing from "@/components/ImagesProcessing/ImagesProcessing";
import LearningRatesGenerator from "@/components/LearningRatesGenerator/LearningRatesGenerator";

// Tell Vue to use Vue Router
Vue.use(Router);

// Define your routes
const routes = [
    { path: '/', component: ImagesProcessing },
    { path: '/quantization-playground', component: QuantizationPlayground },
    { path: '/learning-rates-generator', component: LearningRatesGenerator },
];

// Create the router instance
const router = new Router({
    mode: 'history',
    routes,
});

export default router;
