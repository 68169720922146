// Minimum Variance Quantization

export function mvq(pixels, colorCount) {
    const colorData = [];

    for (let i = 0; i < pixels.length; i += 4) {
        colorData.push([pixels[i], pixels[i + 1], pixels[i + 2]]);
    }

    const quantizationLevels = getOptimalQuantizationLevels(colorData, colorCount);
    const quantizedColors = applyQuantizationLevels(colorData, quantizationLevels);

    const indexedPixels = [];
    for (const [r, g, b] of quantizedColors) {
        indexedPixels.push(r, g, b, 255); // Assuming fully opaque pixels
    }

    return indexedPixels;
}

function getOptimalQuantizationLevels(colorData, colorCount) {
    const colorSpaceSize = 256;
    const dynamicProgrammingTable = new Array(colorCount + 1)
        .fill(null)
        .map(() => new Array(colorSpaceSize).fill(Infinity));
    const quantizationLevels = new Array(colorCount + 1)
        .fill(null)
        .map(() => new Array(colorSpaceSize).fill(0));

    for (let i = 0; i < colorSpaceSize; i++) {
        dynamicProgrammingTable[1][i] = variance(colorData, 0, i) + variance(colorData, i + 1, colorSpaceSize - 1);
        quantizationLevels[1][i] = i;
    }

    for (let k = 2; k <= colorCount; k++) {
        for (let i = 0; i < colorSpaceSize; i++) {
            for (let j = 0; j < i; j++) {
                const cost = dynamicProgrammingTable[k - 1][j] + variance(colorData, j + 1, i);
                if (cost < dynamicProgrammingTable[k][i]) {
                    dynamicProgrammingTable[k][i] = cost;
                    quantizationLevels[k][i] = j;
                }
            }
        }
    }

    return quantizationLevels[colorCount];
}

function applyQuantizationLevels(colorData, quantizationLevels) {
    const quantizedColors = [];

    for (const [r, g, b] of colorData) {
        const qr = quantizationLevels[r];
        const qg = quantizationLevels[g];
        const qb = quantizationLevels[b];
        quantizedColors.push([qr, qg, qb]);
    }

    return quantizedColors;
}

function variance(colorData, start, end) {
    if (start > end) {
        return 0;
    }

    let sum = 0;
    let count = 0;

    for (let i = start; i <= end; i++) {
        const [r, g, b] = colorData[i];
        sum += r + g + b;
        count++;
    }

    const mean = sum / (3 * count);

    let squaredDiffSum = 0;

    for (let i = start; i <= end; i++) {
        const [r, g, b] = colorData[i];
        squaredDiffSum += (r - mean) ** 2 + (g - mean) ** 2 + (b - mean) ** 2;
    }

    return squaredDiffSum / (3 * count);
}