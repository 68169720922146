<template>
  <div class="learning-rate-optimizer">
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-20 md-size-10">
        <span class="md-subheading">Point 1</span>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-size-45">
        <md-field>
          <label>Step</label>
          <md-input v-model.number="point1.x">Learning Step</md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-size-45">
        <md-field>
          <label>Learning Rate</label>
          <md-input v-model.number="point1.y">Learning Rate</md-input>
        </md-field>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-20 md-size-10">
        <span class="md-subheading">Point 2</span>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-size-45">
        <md-field>
          <label>Step</label>
          <md-input v-model.number="point2.x">Learning Step</md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-size-45">
        <md-field>
          <label>Learning Rate</label>
          <md-input v-model.number="point2.y">Learning Rate</md-input>
        </md-field>
      </div>
    </div>
    <md-field>
      <label>Curvature</label>
      <md-input type="range" v-model.number="curvature" min="0" max="10" step="0.01" @input="generateOutput"></md-input>
      {{ curvature.toFixed(2) }}
    </md-field>
    <md-field>
      <label>Output Learning Step Changes Count</label>
      <md-input v-model.number="outputStepCount"></md-input>
    </md-field>
    <md-button class="md-raised md-primary" @click="generateOutput">Run</md-button>
    <canvas id="chart" style="max-width: 1200px; max-height: 720px"></canvas>
    <div v-if="generatedLearningRateText !== null">
      <md-field>
        <label>Generated LR text</label>
        <md-textarea v-model="generatedLearningRateText"></md-textarea>
      </md-field>
      <md-button class="md-raised md-primary" @click="copyToClipboard">Copy</md-button>
    </div>
  </div>
</template>

<script>

import {Chart} from "chart.js/auto";

export default {
  name: "LearningRatesGenerator",
  data() {
    return {
      point1: {x: 25, y: 0.0001},
      point2: {x: 1000, y: 0.00000001},
      curvature: 1,
      outputStepCount: 100,
      chart: null,
      generatedLearningRateText: null,
    };
  },
  methods: {
    generateOutput() {
      const x1 = this.point1.x;
      const x2 = this.point2.x;
      const y1 = this.point1.y;
      const y2 = this.point2.y;

      // Calculate the decay rate
      const decayRate = Math.log(y2 / y1) / ((x2 - x1) * this.curvature);

      // Generate x and y data points for the fitted curve
      const xList = Array.from({length: this.outputStepCount + 1}, (_, i) => Math.round(this.point1.x + i * (this.point2.x - this.point1.x) / this.outputStepCount));
      const yList = xList.map(x => y1 * Math.exp(decayRate * (x - x1)));

      this.generatedLearningRateText = xList
          .map((x, i) => `${yList[i].toExponential(1)}:${x}`)
          .join(", ");
      
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(document.getElementById('chart'), {
        type: 'line',
        data: {
          labels: xList,
          datasets: [
            {
              label: 'Input points',
              data: [this.point1, this.point2],
              borderColor: 'red',
              pointBackgroundColor: 'red',
              fill: false,
              showLine: false,
              pointRadius: 5,
              pointHoverRadius: 7,
              xAxisID: 'xAxes'
            },
            {
              label: 'Learning Rate',
              data: xList.map((x, i) => ({x, y: yList[i]})),
              borderColor: 'blue',
              // pointBackgroundColor: 'blue',
              pointStyle: false,
              fill: false,
              // pointRadius: 3,
              stepped: 'after',
              xAxisID: 'xAxes',
            },
          ],
        },
        options: {
          animation: false,
          scales: {
            xAxes: {
              type: 'linear',
              position: 'bottom',
            },
          },
        },
      });
    },

    copyToClipboard() {
      this.$refs.outputTextField.select();
      document.execCommand('copy');
    },
  },
};
</script>

<style scoped>
.learning-rate-optimizer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>