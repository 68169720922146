<template>
  <div class="image-table">
    <md-table v-model="paginatedImageData" md-card :md-selected-value.sync="selectedImage">
<!--      <md-table-toolbar>-->
<!--        <div class="md-toolbar-section-start">-->
<!--          <h1 class="md-title">Images</h1>-->
<!--        </div>-->

<!--        <div class="md-toolbar-section-end">-->
<!--          <md-button class="md-raised  md-primary" v-if="anyProcessedImages"-->
<!--                     @click="this.$emit('onDownloadingRequested');">Download-->
<!--          </md-button>-->
<!--        </div>-->
<!--      </md-table-toolbar>-->
      <md-table-row slot="md-table-row" slot-scope="{ item }" class="md-primary clickable-row"
                    @click.native="onRowClick(item)">
        <md-table-cell md-label="Image" :md-style="getImageStyle(item)">
          <image-container :item="item" :height="256" :max-width="512"></image-container>
        </md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="name" class="image-table__name-cell">{{ item.name }}</md-table-cell>
      </md-table-row>
      <md-table-pagination :md-page-size="25"
                           :md-page-options="[10, 25, 50, 100]"
                           :md-update="updatePagination"
                           :md-data="localImageData"
                           :md-paginated-data.sync="paginatedImageData"></md-table-pagination>
    </md-table>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImagesProcessing/ImageContainer";

export default {
  components: {ImageContainer},
  props: {
    imageData: Array,
  },
  data() {
    return {
      localImageData: this.imageData,
      selectedImage: null,
      paginatedImageData: [],
    };
  },
  computed: {
    anyProcessedImages() {
      return this.localImageData.some(image => image.processed);
    },
  },
  mounted() {
  },
  watch: {
    imageData(newImageData) {
      this.localImageData = newImageData;
    },
    selectedImage(newImage) {
      if (newImage) {
        this.$emit('image-selected', newImage);
      } else {
        this.$emit('image-unselected', null);
      }
    },
  },
  methods: {
    onRowClick(item) {
      this.selectedImage = null;
      this.selectedImage = item;
    },

    updatePagination(page, pageSize, sort, sortOrder) {
      console.log('pagination has updated', page, pageSize, sort, sortOrder);
    },

    getImageStyle(imageData) {
      return imageData.isPixelated ? {imageRendering: 'pixelated', padding: 0} : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/global.scss';

.image-table {
  max-width: 100%;
  height: 100%;
}

.image-table__name-cell {
  width: 100%;
}

.clickable-row {
  cursor: pointer;
}
</style>
