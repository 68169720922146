<template>
  <div v-if="visible" class="image-info-dialog md-layout" @click="onOverlayClick">
    <md-card class="image-info-dialog__container md-layout-item md-size-50 md-xsmall-size-100 md-small-size-75 md-medium-size-50">
      <md-card-area md-inset>
        <md-card-header>
          <div class="md-title">Image info</div>
        </md-card-header>
        <md-card-content>
          <div>
            <div class="image-info-dialog__image-container">
              <image-container :item="localImageData" :height="512"></image-container>
            </div>
            <div>
              <md-field>
                <!--        <md-field style="display:flex">-->
                <label>Colors count</label>
                <md-input
                    type="range"
                    v-model.number="localImageData.settings.colorCountLimit"
                    min="10"
                    max="99"
                    step="1"
                    @change="rerunProcessing"
                ></md-input>
                {{ localImageData.settings.colorCountLimit }}
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card-area>
      <md-card-content>
        <span class="md-title">Metadata</span>
        <p>{{ localImageData.metadata }}</p>
      </md-card-content>
      <md-card-actions>
        <md-button class="md-dense md-primary" @click.stop="closeDialog">Close</md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImagesProcessing/ImageContainer";

export default {
  name: 'ImageInfoDialog',
  components: {ImageContainer},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ImageData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    localImageData() {
      const defaultData = {
        resultSrc: '',
        settings: {
          colorCountLimit: 10,
        },
        metadata: '',
      };

      return {...defaultData, ...this.ImageData};
    },
  },
  methods: {
    onOverlayClick(event) {
      if (event.target.classList.contains('image-info-dialog')) {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('update:visible', false);
    },
    rerunProcessing() {
      this.$emit('rerun-processing');
    },
  },
};
</script>

<style lang="scss" scoped>
.image-info-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &__container {
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    max-height: 80%;
    overflow: auto;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
