import {processImage} from "@/utils/quantization";

export function GetSuitableStrategy(imageData) {
    if (imageData.aspectRatio === 1) {
        return 'PixelPaintingStrategy';
    } else {
        return 'DefaultStrategy';
    }
}


export class PixelPaintingStrategy {
    execute(sourceImg, file, settings, signal = {aborted: false}) {
        settings.resizingAlgorithm = 'Pixel Painting';
        settings.algorithm = 'Custom+KMC';
        return processImage(sourceImg, file, settings, signal);
    }
}

export class DefaultStrategy {
    execute(sourceImg, file, settings, signal = {aborted: false}) {
            settings.resizingAlgorithm = 'None';
            settings.algorithm = 'KMC';
            return processImage(sourceImg, file, settings, signal);
    }
}