<template>
  <div>
    <md-empty-state
        :md-icon="icon"
        :md-label="title"
        :md-description="description">
      <md-button class="md-primary md-raised" @click="$emit('clicked')">{{buttonText}}</md-button>
    </md-empty-state>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    description: {
      type: String,
      required: true,
      default: ''
    },
    buttonText: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>