<template>
  <div>
    <EmptyState v-if="images.length === 0" icon="cloud_upload" @clicked="startFilesLoading"
                :title="$t('imagesProcessing.emptyState.title')"
                :description="$t('imagesProcessing.emptyState.description')"
                :button-text="$t('imagesProcessing.emptyState.loadButton')"/>
    <image-loader ref="imgLoader" @files-loaded="handleFilesLoaded" @cancel="isLoading=false"
                  style="display: none"></image-loader>
    <div class="images-processing__loading-indicator" v-if="isLoading">
      <md-progress-spinner md-mode="indeterminate" class="md-accent"></md-progress-spinner>
    </div>
    <image-table v-if="images.length > 0" :imageData="images" @image-selected="imageSelected"
                 @image-unselected="imageUnselected"></image-table>
    <div style="display: flex; justify-content: center; align-items: center;">
      <md-button class="md-raised  md-primary" v-if="anyProcessedImages" @click="downloadImages">Download</md-button>
    </div>
    <image-info-dialog :visible="selectedImage !== null" :ImageData="selectedImage" @rerun-processing="rerunProcessing"
                       @update:visible="selectedImage = null"></image-info-dialog>
  </div>
</template>

<script>
import {EmptyState, ImageLoader} from "@/components/ImagesProcessing";
import ImageTable from "@/components/ImagesProcessing/ImageTable";
import ImageData from './ImageData.js';
import ImageInfoDialog from "@/components/ImagesProcessing/ImageInfoDialog";
import {saveAs} from "file-saver";
import JSZip from "jszip";

export default {
  name: "ImagesProcessing",
  components: {
    ImageInfoDialog,
    ImageTable,
    EmptyState,
    ImageLoader
  },
  computed: {
    anyProcessedImages() {
      return this.images.some(image => image.processed);
    },
  },
  data: () => ({
    images: [],
    isLoading: false,
    selectedImage: null,
  }),
  methods: {
    startFilesLoading() {
      this.$refs.imgLoader.onButtonClick();
      this.isLoading = true;
    },

    handleFilesLoaded(files) {
      console.log("loaded files: ", files);
      this.images = files.map(file => {
        const imageData = new ImageData(file);
        imageData.startLoading();
        return imageData;
      });
      this.isLoading = false;
      // Process the images, update other components as necessary
    },

    imageSelected(image) {
      this.selectedImage = image;
      console.log("image selected: ", image);
    },

    imageUnselected() {
      this.selectedImage = null;
      console.log("image unselected");
    },

    rerunProcessing() {
      this.selectedImage.processed = false;
      this.selectedImage.removeFromProcessingQueue();
      this.selectedImage.addToProcessingQueue();

      console.log("rerun processing");
    },

    async downloadImages() {
      const zip = new JSZip();
      const processedImages = this.images.filter(image => image.processed);

      for (const imageData of processedImages) {
        const response = await fetch(imageData.resultSrc);
        const blob = await response.blob();
        zip.file(`${imageData.name}.png`, blob);
      }

      const content = await zip.generateAsync({type: "blob"});
      saveAs(content, "processed_images.zip");
    },
  },
}
</script>

<style scoped>
.images-processing__loading-indicator {
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
}
</style>