import * as d3 from "d3-color";
import * as DeltaE from "delta-e";

export const ColorDistance = {
    distance: function (color1, color2, method = 'Euclidean') {
        if (method.toLowerCase() === 'Euclidean'.toLowerCase()) {
            return this.distanceEuclidean(color1, color2);
        } else if (method.toLowerCase() === 'CIEDE2000'.toLowerCase()) {
            return this.distanceCIEDE2000(color1, color2);
        } else {
            throw new Error('Invalid color distance method');
        }
    },

    distanceEuclidean: function (color1, color2) {
        const [r1, g1, b1] = color1;
        const [r2, g2, b2] = color2;
        return Math.sqrt((r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2);
    },

    distanceCIEDE2000: function (color1, color2) {
        const lab1 = rgbToLab(color1);
        const lab2 = rgbToLab(color2);
        return DeltaE.getDeltaE00(lab1, lab2);
    },
}

function rgbToLab(color) {
    const {r, g, b} = d3.rgb(...color);
    const {l, a, b: labB} = d3.lab(r, g, b);
    return {L: l, A: a, B: labB};
}

