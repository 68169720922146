export default class QuantizationSettings {
    static availableAlgorithms = ['Do not process', 'Custom', 'MMCQ', 'KMC', 'KMC-CIEDE2000', 'MVQ', 'CVQC', 'MVQ+KMC', 'Custom+KMC', 'Custom+KMC-CIEDE2000', 'Custom+MVQ', 'Custom+CVQC'];
    static availableResizingAlgorithms = ['None', 'Pixel Painting'];
    static availableColorComparisonMethods = ['Euclidean', 'CIEDE2000'];
    static availableCentroidInitializationMethods = ['Random', 'K-Means++', 'Top N Distributed'];

    constructor(options = {}) {
        this.colorCountLimit = options.colorCountLimit || 30;
        this.similarityThreshold = options.similarityThreshold || 0.99;
        this.algorithm = options.algorithm || 'Custom+KMC';
        this.resizingAlgorithm = options.resizingAlgorithm || 'Pixel Painting';
        this.colorComparisonMethod = options.colorComparisonMethod || 'Euclidean';
        this.centroidInitialization = options.centroidInitialization || 'Top N Distributed';
        this.cvqcMaxIterations = options.cvqcMaxIterations || 10;
        this.kmcColorSelectionPool = options.kmcColorSelectionPool || this.colorCountLimit * 4;
        this.kmcNumDistributedColors = options.kmcNumDistributedColors || this.colorCountLimit * 0.5;
    }
}