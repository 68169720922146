import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'ru',
    messages: {
        en: {
            "home": 'Home',
            "navigation": 'Navigation',
            "playground.navigator.title": 'Quantization Playground',
            
            "playground.emptyState.title": 'Nothing to see here',
            "playground.emptyState.description": 'There are no images to display. Click the Load Images button to get started.',
            "playground.emptyState.loadButton": 'Load Images',
            
            "imagesProcessing.emptyState.title": 'Load your first images',
            "imagesProcessing.emptyState.description": 'Select and load your files to start processing.',
            "imagesProcessing.emptyState.loadButton": 'Load Images',
            
            "learning-rates-generator.navigator.title": 'Learning Rates Generator',
        },
        ru: {
            "home": 'Домой',
            "navigation": 'Навигация',
            "playground.navigator.title": 'Тест настроек квантования',
            "playground.emptyState.title": 'Здесь пока ничего нет',
            "playground.emptyState.description": 'Загрузите изображения, чтобы начать работу.',
            "playground.emptyState.loadButton": 'Загрузить изображения',

            "imagesProcessing.emptyState.title": 'Загрузите свои изображения',
            "imagesProcessing.emptyState.description": 'Выберите и загрузите файлы, чтобы начать обработку.',
            "imagesProcessing.emptyState.loadButton": 'Загрузить изображения',
            
            "learning-rates-generator.navigator.title": 'Генератор Learning Rate',
        }
    }
})

export default i18n