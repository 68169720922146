import Vue from 'vue';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import App from './App.vue';
import router from './router';
import i18n from "@/i18n";

Vue.use(VueMaterial);
Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
