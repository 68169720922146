<template>
  <div>
    <EmptyState v-if="images.length === 0" icon="sentiment_very_dissatisfied" 
                :title="$t('playground.emptyState.title')"
                :description="$t('playground.emptyState.description')"
                :button-text="$t('playground.emptyState.loadButton')" @clicked="startFilesLoading"/>
    <div v-else>
      <image-table :images="images"></image-table>
      <md-switch v-model="compareAlgorithms">Compare algorithms</md-switch>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <settings-panel @settings-changed="handleSettingsChanged"></settings-panel>
        </div>
        <div class="md-layout-item" v-if="compareAlgorithms">
          <settings-panel @settings-changed="handleComparingSettingsChanged"></settings-panel>
        </div>
      </div>
      <image-processor :images="images" :settings="settings" :comparing-regime="compareAlgorithms"
                       @images-processed="handleImagesProcessed"></image-processor>
      <ProcessedImages v-if="processedImages.length > 0" :processedImages="processedImages"/>
    </div>
    <image-loader ref="imgLoader" @files-loaded="handleFilesLoaded" style="display: none"></image-loader>
  </div>
</template>

<script>
import {
  ImageLoader,
  ImageTable,
  SettingsPanel,
  ImageProcessor,
  ProcessedImages,
  EmptyState
} from '@/components/QuantizationPlayground';

export default {
  name: 'QuantizationPlayground',
  components: {
    ImageLoader,
    ImageTable,
    SettingsPanel,
    ImageProcessor,
    ProcessedImages,
    EmptyState,
  },
  data() {
    return {
      images: [],
      settings: [],
      processedImages: [],
      compareAlgorithms: false,
    };
  },
  methods: {
    startFilesLoading() {
      console.log(this.$refs.imgLoader);
      this.$refs.imgLoader.onButtonClick();
    },
    handleFilesLoaded(files) {
      console.log("loaded files: ", files);
      this.images = files;
      // Process the images, update other components as necessary
    },
    handleSettingsChanged(newSettings) {
      this.settings[0] = newSettings;
    },
    handleComparingSettingsChanged(newSettings) {
      this.settings[1] = newSettings;
    },
    handleImagesProcessed(newProcessedImages) {
      console.log(newProcessedImages);
      if (this.processedImages.length > 0) {
        for (let i = 0; i < this.processedImages.length; i++) {
          URL.revokeObjectURL(this.processedImages[i].leftImage);
          URL.revokeObjectURL(this.processedImages[i].rightImage);
        }
      }
      this.processedImages = newProcessedImages;
      // Update the app based on the processed images
    },
  },
};
</script>

<style scoped>

</style>