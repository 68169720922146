const sizeMap = {
    512: {targetWidth: 64, targetHeight: 64},
    1024: {targetWidth: 128, targetHeight: 128}
};

/**
 * Creates a canvas and context with the given dimensions
 * @param {number} width The width of the canvas
 * @param {number} height The height of the canvas
 * @returns {Object} An object containing the canvas and context
 */
function createCanvasAndContext(width, height) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;

    return { canvas, ctx };
}

/**
 * Scales a pixel image to 64x64 or 128x128 pixels
 * @param {HTMLImageElement} image The input pixel image
 * @returns {Object} An object containing the scaled image and its pixel data
 * @throws {Error} If the input image does not have equal width and height
 */
export async function resizePixelPainting(image) {
    const { width, height } = image;

    // Check that the input image has equal width and height
    if (width !== height) {
        throw new Error('Input image must have equal width and height');
    }

    const targetSize = getTargetSize(width, height);
    if (!targetSize) {
        // Image does not need to be resized
        let { ctx } = createCanvasAndContext(width, height);

        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, width, height);

        ctx = null;

        return { imageData, image };
    }

    const { targetWidth, targetHeight } = targetSize;
    let { canvas, ctx } = createCanvasAndContext(targetWidth, targetHeight);

    // Resize the image
    ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

    const downscaledImageData = ctx.getImageData(0, 0, targetWidth, targetHeight);
    const downscaledImage = canvas.toDataURL();

    canvas = null;
    ctx = null;

    return { imageData: downscaledImageData, image: downscaledImage };
}


function getTargetSize(width, height) {
    if (width in sizeMap && height in sizeMap) {
        return sizeMap[width];
    }
    return null;
}