<template>
  <div class="image-loader md-card">
    <md-button class="md-raised md-primary" @click="onButtonClick" ref="loadImagesButton">Load Images</md-button>
    <input type="file" accept="image/*" multiple ref="fileInput" @change="onFileChange" style="display: none"/>
  </div>
</template>

<script>
export default {
  name: 'ImageLoader',
  methods: {
    onButtonClick() {
      window.addEventListener('focus', this.onFocusedBack);
      this.$refs.fileInput.click();
      this.fileChangeCalled = false;
    },
    
    onFileChange(event) {
      console.log("onFileChange");
      this.fileChangeCalled = true;
      const files = event.target.files || event.dataTransfer.files;
      if (!files || !files.length) {
        console.log("cancelled");
        this.$emit('cancel');
      } else {
        this.$emit('files-loaded', Array.from(files));
        event.target.value = null;
      }
    },

    onFocusedBack() {
      window.removeEventListener('focus', this.onFocusedBack);
      setTimeout(() => {
        if (!this.fileChangeCalled) {
          this.$emit('cancel');
        }
      }, 250);
    }
  },
};
</script>

<style scoped>
</style>
