class ColorBox {
    constructor(pixels) {
        this.pixels = pixels;
        this.color = this.averageColor();
    }

    averageColor() {
        const sumColor = this.pixels.reduce(
            ([rSum, gSum, bSum], [r, g, b]) => [rSum + r, gSum + g, bSum + b],
            [0, 0, 0]
        );
        const count = this.pixels.length;
        return sumColor.map((x) => Math.round(x / count));
    }

    split() {
        const axis = this.getSplitAxis();
        this.pixels.sort((a, b) => a[axis] - b[axis]);
        const midIndex = Math.floor(this.pixels.length / 2);
        const [box1Pixels, box2Pixels] = [this.pixels.slice(0, midIndex), this.pixels.slice(midIndex)];
        return [new ColorBox(box1Pixels), new ColorBox(box2Pixels)];
    }

    getSplitAxis() {
        const colorRanges = this.getColorRanges();
        return colorRanges.indexOf(Math.max(...colorRanges));
    }

    getColorRanges() {
        const minColor = [255, 255, 255];
        const maxColor = [0, 0, 0];

        for (const [r, g, b] of this.pixels) {
            minColor[0] = Math.min(minColor[0], r);
            minColor[1] = Math.min(minColor[1], g);
            minColor[2] = Math.min(minColor[2], b);
            maxColor[0] = Math.max(maxColor[0], r);
            maxColor[1] = Math.max(maxColor[1], g);
            maxColor[2] = Math.max(maxColor[2], b);
        }

        return [maxColor[0] - minColor[0], maxColor[1] - minColor[1], maxColor[2] - minColor[2]];
    }
}

export function mmcq(pixels, targetColorCount) {
    const pixelsArray = [];

    for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];
        pixelsArray.push([r, g, b, i]);
    }

    const initialBox = new ColorBox(pixelsArray);
    let colorBoxes = [initialBox];

    while (colorBoxes.length < targetColorCount) {
        const largestBox = colorBoxes.reduce((maxBox, box) => {
            return maxBox.pixels.length > box.pixels.length ? maxBox : box;
        });

        const index = colorBoxes.indexOf(largestBox);
        colorBoxes.splice(index, 1);

        const [box1, box2] = largestBox.split();
        colorBoxes.push(box1, box2);
    }

    const reducedPixelData = new Array(pixels.length).fill(0);

    for (const box of colorBoxes) {
        const [r, g, b] = box.color;
        for (const pixelData of box.pixels) {
            reducedPixelData[pixelData[3]] = r;
            reducedPixelData[pixelData[3] + 1] = g;
            reducedPixelData[pixelData[3] + 2] = b;
            reducedPixelData[pixelData[3] + 3] = 255; // Assuming fully opaque pixels
        }
    }

    return reducedPixelData;
}
