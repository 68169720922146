<template>
  <div id="app" class="page-container full-height">
    <md-app class="full-height" md-waterfall md-mode="fixed">
      <md-app-toolbar class="md-primary">
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start">
            <md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible">
              <md-icon>menu</md-icon>
            </md-button>
            <span class="md-title">AI Images Converter</span>
          </div>
        </div>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-persistent="mini">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span>{{$t('navigation')}}</span>

          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-list>
          <md-list-item @click="navigateToRoute('/');">
            <md-icon>home</md-icon>
            <span class="md-list-item-text">{{ $t('home') }}</span>
          </md-list-item>
          
          <md-list-item @click="navigateToRoute('/quantization-playground');">
            <md-icon>tune</md-icon>
            <span class="md-list-item-text">{{ $t('playground.navigator.title') }}</span>
          </md-list-item>
          
          <md-list-item @click="navigateToRoute('/learning-rates-generator');">
            <md-icon>timeline</md-icon>
            <span class="md-list-item-text">{{ $t('learning-rates-generator.navigator.title') }}</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content>
        <router-view></router-view>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({
    menuVisible: false
  }),
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    },
    navigateToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    }
  },
};
</script>

<style>
.full-height {
  height: 100%;
}
</style>
