<!-- src/components/ImageProcessor.vue -->
<template>
  <div class="image-processor">
    <md-button class="image-processor__button md-raised md-primary" @click="processImages">Process Images</md-button>
  </div>
</template>

<script>
// import { processImage } from '../utils/imageProcessing';
import {processFile, compareQuantization} from '@/utils/quantization';

export default {
  name: 'ImageProcessor',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Array,
      default: () => [],
    },
    comparingRegime: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async processImages() {
      if (!this.images.length) return;

      const processedImages = await this.processImagesSequentially();

      this.$emit('images-processed', processedImages);
    },

    async processImagesSequentially() {
      const processedImages = [];

      for (const image of this.images) {
        if (this.comparingRegime && this.settings.length >= 2) {
          const processedImage = await compareQuantization(image, this.settings[0], this.settings[1]);
          processedImages.push(processedImage);
        } else if (!this.comparingRegime && this.settings.length >= 1) {
          const processedImage = await processFile(image, this.settings[0]);
          processedImages.push(processedImage);
        } else {
          console.error("Wrong count of settings");
        }
      }

      return processedImages;
    }
  },
};
</script>

<style scoped>
.image-processor__button {
  /* Styles for the Process Images button */
}
</style>
