<template>
  <div class="settings-panel md-card">
    <md-card-header>
      <div class="md-title">Settings</div>
    </md-card-header>
    <div class="settings-panel__field">
      <md-card-content>
        <md-field>
          <label for="algorithm">Color Quantization Algorithm</label>
          <md-select v-model="algorithm" name="algorithm" id="algorithm" @input="onSettingsChanged">
            <md-option v-for="option in algorithmOptions" :key="option" :value="option">{{ option }}</md-option>
          </md-select>
        </md-field>
        <md-field v-if="colorCountOptionAlgorithms.some(word => algorithm.toLowerCase().includes(word))">
          <label>Color count limit</label>
          <md-input v-model.number="colorCountLimit" type="number" min="1" @input="onSettingsChanged"></md-input>
        </md-field>
        <div v-if="algorithm.toLowerCase().includes('custom')" class="md-layout md-gutter"
             style="margin-left:0; margin-right:0">
          <md-field class="md-layout-item md-size-75">
            <label>Colors similarity threshold</label>
            <md-input type="range" v-model.number="similarityThreshold" min="0" max="1" step="0.001"
                      @input="onSettingsChanged"></md-input>
            {{ (similarityThreshold * 100).toFixed(1) }}%
          </md-field>
          <md-field class="md-layout-item md-size-20">
            <label>Value</label>
            <md-input v-model="similarityThreshold" type="number"></md-input>
          </md-field>
        </div>
        <md-field>
          <label>Color comparision method</label>
          <md-select v-model="colorComparisonMethod" name="colorComparisonMethod" id="colorComparisonMethod"
                     @input="onSettingsChanged">
            <md-option v-for="option in colorComparisonMethodOptions" :key="option" :value="option">{{ option }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="resizingAlgorithm">Resizing Algorithm</label>
          <md-select v-model="resizingAlgorithm" name="resizingAlgorithm" id="resizingAlgorithm"
                     @input="onSettingsChanged">
            <md-option v-for="option in resizingAlgorithmOptions" :key="option" :value="option">{{ option }}</md-option>
          </md-select>
        </md-field>
        <md-field v-if="algorithm.toLowerCase().includes('kmc') || algorithm.toLowerCase().includes('cvqc')">
          <label for="centroidInitialization">Centroid Initialization</label>
          <md-select v-model="centroidInitialization" name="centroidInitialization" id="centroidInitialization"
                     @input="onSettingsChanged">
            <md-option v-for="option in centroidInitializationOptions" :key="option" :value="option">{{ option }}
            </md-option>
          </md-select>
        </md-field>
        <md-field v-if="algorithm.toLowerCase().includes('kmc') && centroidInitialization.toLowerCase() === 'top n distributed'">
          <label>KMC Color Selection Pool</label>
          <md-input type="range" v-model.number="kmcColorSelectionPool" min="0" max="400" step="1"
                    @input="onSettingsChanged"></md-input>
          {{ kmcColorSelectionPool }}
        </md-field>
        <md-field v-if="algorithm.toLowerCase().includes('kmc') && centroidInitialization.toLowerCase() === 'top n distributed'">
          <label>KMC Distributed Colors Count</label>
          <md-input type="range" v-model.number="kmcNumDistributedColors" min="0" max="99" step="1"
                    @input="onSettingsChanged"></md-input>
          {{ kmcNumDistributedColors }}
        </md-field>
      </md-card-content>
    </div>
    <!-- Add more settings fields here as needed -->
  </div>
</template>

<script>
import QuantizationSettings from "@/utils/QuantizationSettings";

export default {
  name: 'SettingsPanel',
  data() {
    return {
      colorCountLimit: 30,
      similarityThreshold: 0.99,
      algorithm: 'Custom+KMC',
      compareAlgorithms: false,
      resizingAlgorithm: 'Pixel Painting',
      colorComparisonMethod: 'Euclidean',
      centroidInitialization: 'Top N Distributed',
      cvqcMaxIterations: 10,
      kmcColorSelectionPool: 120,
      kmcNumDistributedColors: 15,

      algorithmOptions: QuantizationSettings.availableAlgorithms,
      resizingAlgorithmOptions: QuantizationSettings.availableResizingAlgorithms,
      colorComparisonMethodOptions: QuantizationSettings.availableColorComparisonMethods,
      centroidInitializationOptions: QuantizationSettings.availableCentroidInitializationMethods,

      colorCountOptionAlgorithms: ['mmcq', 'kmc', 'mvq', 'cvqc'],
    };
  },

  methods: {
    onSettingsChanged() {
      const newSettings = new QuantizationSettings({
        colorCountLimit: this.colorCountLimit,
        similarityThreshold: this.similarityThreshold,
        algorithm: this.algorithm,
        compareAlgorithms: this.compareAlgorithms,
        resizingAlgorithm: this.resizingAlgorithm,
        colorComparisonMethod: this.colorComparisonMethod,
        centroidInitialization: this.centroidInitialization,
        cvqcMaxIterations: this.cvqcMaxIterations,
        kmcColorSelectionPool: this.kmcColorSelectionPool,
        kmcNumDistributedColors: this.kmcNumDistributedColors,
      });
      this.$emit('settings-changed', newSettings);
    },
  },
  mounted() {
    this.onSettingsChanged();
  }
};
</script>

<style scoped>
.settings-panel {
  /* Styles for the settings panel container */
}

.settings-panel__field {
  /* Styles for each individual settings field */
}
</style>
