// Centroidal Voronoi Diagram-based Color Quantization
import {CentroidInitialization} from "@/utils/kmc";
import {ColorDistance} from "@/utils/colorDistance";

export const cvqc = {
    quantize: function (pixels, width, height, K, centroidInitialization, colorComparisionMethod = "Euclidean", maxIterations = 10) {
        return quantizeColors(pixels, width, height, K, centroidInitialization, colorComparisionMethod, maxIterations);
    }
}

function quantizeColors(pixels, width, height, K, centroidInitialization, colorComparisionMethod = "Euclidean", maxIterations = 10) {
    function distance(color1, color2) {
        return ColorDistance.distance(color1, color2, colorComparisionMethod);
    }

    function initializeCentroids(pixels, k) {
        switch (centroidInitialization.toLowerCase()) {
            case 'random':
                return CentroidInitialization.initCentroidsRandom(pixels, k);
            case 'k-means++':
                return CentroidInitialization.initCentroidsPlusPlus(pixels, k, distance);
            case 'top n distributed':
                return CentroidInitialization.initCentroidsTopNDistributed(pixels, k, k * 0.5, k * 4, distance);
            default:
                throw new Error('Invalid centroid initialization method');
        }
    }

    let centroids = initializeCentroids(pixels, K).map(color => ({r: color[0], g: color[1], b: color[2]}));
    let outputPixels = new Uint8ClampedArray(width * height * 4);
    let colors = [];

    for (let i = 0; i < pixels.length; i += 4) {
        colors.push({
            r: pixels[i],
            g: pixels[i + 1],
            b: pixels[i + 2],
            a: pixels[i + 3],
        });
    }

    for (let iter = 0; iter < maxIterations; iter++) {
        let clusters = Array(K).fill(0).map(() => ({color: {r: 0, g: 0, b: 0, a: 0}, count: 0}));

        // Assign pixels to the closest centroids
        for (let i = 0; i < colors.length; i++) {
            let color = colors[i];
            let minDist = Infinity;
            let centroidIndex = -1;

            for (let j = 0; j < K; j++) {
                let dist = distance([color.r, color.g, color.b], [centroids[j].r, centroids[j].g, centroids[j].b]);

                if (dist < minDist) {
                    minDist = dist;
                    centroidIndex = j;
                }
            }
            
            clusters[centroidIndex].color.r += color.r;
            clusters[centroidIndex].color.g += color.g;
            clusters[centroidIndex].color.b += color.b;
            clusters[centroidIndex].count++;
        }

        // Update the centroids
        for (let i = 0; i < K; i++) {
            if (clusters[i].count > 0) {
                centroids[i].r = clusters[i].color.r / clusters[i].count;
                centroids[i].g = clusters[i].color.g / clusters[i].count;
                centroids[i].b = clusters[i].color.b / clusters[i].count;
            }
        }
    }

    // Replace the original colors with the quantized ones in the output array
    for (let i = 0; i < colors.length; i++) {
        let color = colors[i];
        let minDist = Infinity;
        let centroidIndex = -1;

        for (let j = 0; j < K; j++) {
            let dist = distance([color.r, color.g, color.b], [centroids[j].r, centroids[j].g, centroids[j].b]);

            if (dist < minDist) {
                minDist = dist;
                centroidIndex = j;
            }
        }

        let outputIndex = i * 4;
        outputPixels[outputIndex] = centroids[centroidIndex].r;
        outputPixels[outputIndex + 1] = centroids[centroidIndex].g;
        outputPixels[outputIndex + 2] = centroids[centroidIndex].b;
        outputPixels[outputIndex + 3] = color.a; // Preserve the alpha value
    }
    return outputPixels;
}