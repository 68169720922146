<template>
  <div class="image-container">
    <img
        v-if="item.processed"
        :src="item.resultSrc"
        class="image-container__image--processed"
        :alt="item.name"
        :style="{ height: height + 'px', maxWidth: maxWidth + 'px' }"
    />
    <div
        v-else-if="item.loaded"
        class="image-placeholder"
        :style="{ width: item.aspectRatio * height + 'px', height: height + 'px' }"
    ></div>
    <div v-else class="image-placeholder" :style="{ width: height + 'px', height: height + 'px' }"></div>
  </div>
</template>

<script>
export default {
  name: 'ImageContainer',
  props: {
    height: {
      type: Number,
      default: 256,
    },
    maxWidth: {
      type: Number,
      default: 1024,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/global.scss";


.image-container {
  position: relative;
  display: inline-block;
  image-rendering: pixelated;

  &__image--processed {
    min-width: 256px;
    border-radius: 4px;
  }
}
</style>