import {mmcq} from './mmcq';
import {kmc} from "./kmc";
import {mvq} from "@/utils/mvq";
import {resizePixelPainting} from "@/utils/resizing";
import {reduceColors} from "@/utils/customColorReduction";
import {cvqc} from "@/utils/cvqc";

const canvas = document.createElement('canvas');
canvas.willReadFrequently = true;
const ctx = canvas.getContext('2d');

export async function processFile(file, settings) {
    const image = await loadImage(file);
    return processImage(image, file, settings);
}

export async function processImage(image, file, settings, signal = {aborted: false}) {
    console.log('Settings', settings);
    if (settings.resizingAlgorithm.toLowerCase() === 'pixel painting') {
        const {imageData} = await resizePixelPainting(image);
        ctx.canvas.width = imageData.width;
        ctx.canvas.height = imageData.height;
        ctx.putImageData(imageData, 0, 0);
    } else {
        ctx.canvas.width = image.width;
        ctx.canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
    }

    if (signal.aborted) {
        console.warn("Processing aborted");
        return;
    }

    // Get the pixels from the canvas context
    const pixels = getPixels(ctx);
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

    // Group the pixels by color while ignoring the alpha channel
    const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

    if (settings.algorithm.toLowerCase() === 'custom') {
        // CUSTOM
        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        const newPixels = createPixelArray(newColorGroups, width, height);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + newColorGroups.length);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: newColorGroups.length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'mmcq') {
        // MMCQ
        const newPixels = mmcq(pixels, settings.colorCountLimit);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'kmc') {
        // KMC
        const newPixels = kmc.quantize(pixels, settings.colorCountLimit, settings.centroidInitialization, 'euclidean', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'kmc-ciede2000') {
        // KMC
        const newPixels = kmc.quantize(pixels, settings.colorCountLimit, settings.centroidInitialization, 'ciede2000', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'mvq') {
        // MVQ
        const newPixels = mvq(pixels, settings.colorCountLimit);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'cvqc') {
        // CVQC
        const newPixels = cvqc.quantize(pixels, width, height, settings.colorCountLimit, settings.centroidInitialization, settings.colorComparisonMethod, settings.cvqcMaxIterations);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'mvq+kmc') {
        let newPixels = mvq(pixels, settings.colorCountLimit);
        newPixels = kmc.quantize(newPixels, settings.colorCountLimit, settings.centroidInitialization, 'euclidean', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + settings.colorCountLimit);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'custom+kmc') {
        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = kmc.quantize(newPixels, settings.colorCountLimit, settings.centroidInitialization, 'euclidean', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + newColorGroups.length);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'custom+kmc-ciede2000') {
        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = kmc.quantize(newPixels, settings.colorCountLimit, settings.centroidInitialization, 'ciede2000', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + newColorGroups.length);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'custom+mvq') {
        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = mvq(newPixels, settings.colorCountLimit);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + newColorGroups.length);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else if (settings.algorithm.toLowerCase() === 'custom+cvqc') {
        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = cvqc.quantize(newPixels, width, height, settings.colorCountLimit, settings.centroidInitialization, settings.colorComparisonMethod, settings.cvqcMaxIterations);
        const resizedImage = createImageFromPixelArray(pixels, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        console.log("Original groups: " + colorGroups.length + ", reduced: " + newColorGroups.length);
        return {
            file,
            name: file.name,
            original: resizedImage,
            processed: processedImage,
            originalColorsCount: colorGroups.length,
            processedColorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length,
            originalTitle: 'Original',
            processedTitle: settings.algorithm,
        };
    } else {
        console.error("Wrong algorithm: " + settings.algorithm);
    }
}

export async function compareQuantization(file, leftSettings, rightSettings) {
    const originalImage = await loadImage(file);
    await resizeImage(originalImage);

    // Get the pixels from the canvas context
    const pixels = getPixels(ctx);
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

    const leftData = quantizeColors(pixels, leftSettings, leftSettings.algorithm, width, height);
    const rightData = quantizeColors(pixels, rightSettings, rightSettings.algorithm, width, height);

    return {
        file,
        name: file.name,
        original: leftData.image,
        processed: rightData.image,
        originalColorsCount: leftData.colorsCount,
        processedColorsCount: rightData.colorsCount,
        originalTitle: leftSettings.algorithm,
        processedTitle: rightSettings.algorithm,
    };
}

function quantizeColors(pixels, settings, algorithm, width, height) {
    if (algorithm.toLowerCase() === 'do not process') {
        return {
            image: createImageFromPixelArray(pixels, width, height),
            colorsCount: 'n\\a'
        };
    } else if (algorithm.toLowerCase() === 'custom') {
        console.log("Used custom algorithm");
        // Group the pixels by color while ignoring the alpha channel
        const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        const newPixels = createPixelArray(newColorGroups, width, height);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: newColorGroups.length
        };
    } else if (algorithm.toLowerCase() === 'mmcq') {
        // MMCQ
        const newPixels = mmcq(pixels, settings.colorCountLimit);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'kmc') {
        // KMC
        const newPixels = kmc.quantize(pixels, settings.colorCountLimit, settings.centroidInitialization, 'euclidean', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'kmc-ciede2000') {
        // KMC-CIEDE2000
        const newPixels = kmc.quantize(pixels, settings.colorCountLimit, settings.centroidInitialization, 'ciede2000', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'mvq') {
        // MVQ
        const newPixels = mvq(pixels, settings.colorCountLimit);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'cvqc') {
        // CVQC
        const newPixels = cvqc.quantize(pixels, width, height, settings.colorCountLimit, settings.centroidInitialization, settings.colorComparisonMethod, settings.cvqcMaxIterations);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'custom+kmc') {
        // Group the pixels by color while ignoring the alpha channel
        const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = kmc.quantize(newPixels, settings.colorCountLimit, settings.centroidInitialization, 'euclidean', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'custom+kmc-ciede2000') {
        // Group the pixels by color while ignoring the alpha channel
        const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = kmc.quantize(newPixels, settings.colorCountLimit, settings.centroidInitialization, 'ciede2000', settings.kmcNumDistributedColors, settings.kmcColorSelectionPool);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'custom+mvq') {
        // Group the pixels by color while ignoring the alpha channel
        const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = mvq(newPixels, settings.colorCountLimit);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else if (algorithm.toLowerCase() === 'custom+cvqc') {
        // Group the pixels by color while ignoring the alpha channel
        const colorGroups = groupPixelsByColorIgnoreAlpha(pixels);

        const newColorGroups = reduceColors(colorGroups, settings.similarityThreshold, settings.colorComparisonMethod);
        let newPixels = createPixelArray(newColorGroups, width, height);
        newPixels = cvqc.quantize(newPixels, width, height, settings.colorCountLimit, settings.centroidInitialization, settings.colorComparisonMethod, settings.cvqcMaxIterations);
        const processedImage = createImageFromPixelArray(newPixels, width, height);
        return {
            image: processedImage,
            colorsCount: groupPixelsByColorIgnoreAlpha(newPixels).length
        };
    } else {
        console.error("Wrong algorithm: " + settings.algorithm);
    }
}

/**
 * Loads an image file and returns a promise that resolves with the image when it's loaded
 * @param {File} file The image file to load
 * @returns {Promise} A promise that resolves with the loaded image
 */
function loadImage(file) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        // Create an object URL for the file and set it as the image source
        image.src = URL.createObjectURL(file);

        // Wait for the image to load
        image.onload = () => {
            // Once the image has loaded, resolve the promise with the image
            resolve(image);
            // Revoke the object URL to free up memory
            // URL.revokeObjectURL(image.src);
        };

        // If there's an error loading the image, reject the promise with the error
        image.onerror = (error) => {
            reject(error);
        };
    });
}


function getImageFromData(imageData) {
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0);
    const image = new Image();
    image.src = canvas.toDataURL();
    return image;
}

function resizeImage(image) {
    return new Promise((resolve) => {
        const {width, height} = image;

        let targetWidth;
        let targetHeight;

        if (width === 512 && height === 512) {
            targetWidth = 64;
            targetHeight = 64;
        } else if (width === 1024 && height === 1024) {
            targetWidth = 128;
            targetHeight = 128;
        } else {
            resolve(image);
            return;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // Set imageSmoothingEnabled to false for nearest-neighbor downsampling
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

        const downscaledImageData = ctx.getImageData(0, 0, targetWidth, targetHeight);
        const downscaledImage = getImageFromData(downscaledImageData);

        resolve(downscaledImage);
    });
}

function createImageFromPixelArray(pixelArray, width, height) {
    canvas.width = width;
    canvas.height = height;

    const imageData = ctx.createImageData(width, height);
    imageData.data.set(pixelArray);
    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL('image/png');
}

function getPixels(ctx) {
    const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    return imageData.data;
}

function groupPixelsByColorIgnoreAlpha(pixels) {
    const colorGroups = {};

    for (let i = 0; i < pixels.length; i += 4) {
        // Get the RGB values for this pixel
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];

        // Create a unique key for this color
        const colorKey = `${r},${g},${b}`;

        // If this color hasn't been seen before, add it to the color groups
        if (!colorGroups[colorKey]) {
            colorGroups[colorKey] = [];
        }

        // Add this pixel to the color group for this color
        const pixelIndex = i / 4;
        colorGroups[colorKey].push(pixelIndex);
    }

    // Convert colorGroups object to an array
    return Object.entries(colorGroups).map(([colorKey, pixels]) => {
        const color = colorKey.split(',').map(Number);
        return {color, pixels};
    });
}

// const MAX_DISTANCE = Math.sqrt(255 ** 2 + 255 ** 2 + 255 ** 2);
// const SQR_MAX_DISTANCE = MAX_DISTANCE ** 2;
//
// function reduceColors(colorGroups, similarityThreshold) {
//     console.log("similarity threshold: " + similarityThreshold);
//
//     function colorSimilarity(color1, color2) {
//         const [r1, g1, b1] = color1;
//         const [r2, g2, b2] = color2;
//         const squaredDistance = (r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2;
//         return 1 - squaredDistance / SQR_MAX_DISTANCE;
//     }
//
//     function findMostSimilarGroup(currentGroup, groups) {
//         let maxSimilarity = 0;
//         let mostSimilarGroup = null;
//
//         for (const group of groups) {
//             if (currentGroup === group) continue; // Skip the current group
//
//             const groupColor = group.color;
//             const similarity = colorSimilarity(currentGroup.color, groupColor);
//
//             if (similarity > maxSimilarity) {
//                 maxSimilarity = similarity;
//                 mostSimilarGroup = group;
//             }
//         }
//
//         return {mostSimilarGroup, maxSimilarity};
//     }
//
//     const newColorGroups = [...colorGroups];
//
//     let reducingCounter = 0;
//     let groupMerged = true;
//
//     while (groupMerged) {
//         groupMerged = false;
//
//         for (let i = 0; i < newColorGroups.length; i++) {
//             const group = newColorGroups[i];
//             const {mostSimilarGroup, maxSimilarity} = findMostSimilarGroup(group, newColorGroups);
//
//             if (maxSimilarity >= similarityThreshold && group !== mostSimilarGroup) {
//                 reducingCounter++;
//                 groupMerged = true;
//
//                 if (group.pixels.length < mostSimilarGroup.pixels.length) {
//                     mostSimilarGroup.pixels.push(...group.pixels);
//                     newColorGroups.splice(newColorGroups.indexOf(group), 1);
//                 } else {
//                     group.pixels.push(...mostSimilarGroup.pixels);
//                     newColorGroups.splice(newColorGroups.indexOf(mostSimilarGroup), 1);
//                 }
//
//                 break; // Если мы объединили группы, прерываем текущий цикл for и начинаем сначала с помощью while
//             }
//         }
//     }
//     console.log('reduced: ' + reducingCounter);
//
//     return newColorGroups;
// }


function createPixelArray(colorGroups, width, height) {
    const pixelArray = new Uint8ClampedArray(width * height * 4);

    for (const group of colorGroups) {
        const [r, g, b] = group.color;

        for (const pixelIndex of group.pixels) {
            const idx = pixelIndex * 4;
            pixelArray[idx] = r;
            pixelArray[idx + 1] = g;
            pixelArray[idx + 2] = b;
            pixelArray[idx + 3] = 255; // Alpha channel
        }
    }

    return pixelArray;
}

// function indexToCoords(index, width) {
//     const x = index % width;
//     const y = Math.floor(index / width);
//     return { x, y };
// }
//
// function coordsToIndex(x, y, width) {
//     return y * width + x;
// }

