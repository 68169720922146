import QuantizationSettings from "@/utils/QuantizationSettings";
import {ProcessingQueue} from "@/components/ImagesProcessing/ProcessingQueue";
import {GetSuitableStrategy} from "@/components/ImagesProcessing/ProcessingStrategy";

const processingQueue = new ProcessingQueue();

export default class ImageData {
    constructor(file) {
        this.name = file.name.replace(/\.[^/.]+$/, "");
        this.file = file;
        this.fileUrl = URL.createObjectURL(file);
        this.metadata = null;
        this.loaded = false;
        this.processed = false;
        this.aspectRatio = 1;
        this.isPixelated = false;
        this.sourceImg = new Image();
        this.resultImg = this.sourceImg;
        this.resultSrc = this.fileUrl;
        this.processingStrategy = null;
        this.settings = new QuantizationSettings();
    }

    startLoading() {
        this.sourceImg.onload = () => {
            this.aspectRatio = this.sourceImg.width / this.sourceImg.height;
            this.loaded = true;
            this.isPixelated = this.aspectRatio === 1;
            this.processingStrategy = GetSuitableStrategy(this);

            if (this.aspectRatio === 1) {
                this.settings.resizingAlgorithm = 'Pixel Painting';
            } else {
                this.settings.resizingAlgorithm = 'None';
            }

            this.addToProcessingQueue();
        };
        this.sourceImg.src = this.fileUrl;
    }

    addToProcessingQueue() {
        processingQueue.add(this);
    }

    removeFromProcessingQueue() {
        processingQueue.remove(this);
    }

    processingFinished(result) {
        this.processed = true;
        this.resultSrc = result;
    }

    processingFailed(error) {
        console.error("Error during processing:", error);
    }
}

