<!-- src/components/ImageTable.vue -->
<template>
  <div class="md-card">
    <div class="md-card-content">
      <div class="image-table">
        <md-table v-model="loadedImages">
          <md-table-toolbar>
            <h1 class="md-title">Images</h1>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select>
            <md-table-cell md-label="Thumbnail">
              <img class="image-table__thumbnail" :src="item.url" :alt="item.name"/>
            </md-table-cell>
            <md-table-cell md-label="Filename">{{ item.name }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageTable',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log("ImageTable created with props:", this.loadedImages);
  },
  data() {
    return {
      loadedImages: [],
    };
  },
  mounted() {
    this.fillTable(this.images);
  },
  methods: {
    fillTable(images) {
      this.loadedImages = images.map((file) => {
        const url = URL.createObjectURL(file);
        return {selected: false, url, name: file.name};
      });
    },
  },
  watch: {
    images(newImages) {
      this.fillTable(newImages);
    },
  },
};
</script>

<style scoped>
.image-table {
  /* Styles for the image table container */
}

.image-table__thumbnail {
  /* Styles for the image thumbnail */
  max-height: 128px;
  max-width: 128px;
  border-radius: 3px;
}
</style>
